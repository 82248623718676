import React from 'react'

const aspectRatio = 1.5

const IMG = ({className, fluid, alt}) => fluid ? (
  <img className={className} src={fluid.src} srcSet={fluid.srcSet} sizes={fluid.sizes} alt={alt} />
) : (
  <svg width={1000 * aspectRatio} height={1000} viewBox={`0 0 ${1000 * aspectRatio} 1000`}>
    <rect width={1000 * aspectRatio} height={1000} fill={'rgba(0, 0, 0, 0.2)'} />
  </svg>
)

IMG.defaultProps = {
  alt: ''
}

export default IMG
