import { graphql, useStaticQuery } from "gatsby";

function useSettings() {
	const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          ...Settings
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}

export default useSettings
