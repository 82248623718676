const hex = {
  green: '#1f4c26',
  darkGreen: '#004F35',
  lightGreen: '#ebf7f3',
  red: '#EF3C18',
  lightRed: '#FFEFED',
  white: '#FFFFFF',
  offWhite: '#D5D5CD'
}

const theme = {
  green: {
    main: {
      background: hex.white,
      color: hex.darkGreen,
      highlight: hex.red
    },
    nav: {
      background: hex.green,
      color: hex.white
    },
    highlight: {
      background: hex.red,
      color: hex.white
    }
  },
  split: {
    main: {
      background: hex.white,
      color: hex.green
    },
    nav: {
      background: hex.green,
      color: hex.white
    },
    highlight: {
      background: hex.red,
      color: hex.white
    }
  },
  red: {
    main: {
      background: hex.offWhite,
      color: hex.red,
      highlight: hex.lightRed
    },
    nav: {
      background: hex.lightRed,
      color: hex.red
    },
    highlight: {
      background: hex.red,
      color: hex.white
    }
  },
  white: {
    main: {
      background: hex.white,
      color: hex.green
    },
    nav: {
      background: hex.green,
      color: hex.white
    },
    highlight: {
      background: hex.red,
      color: hex.white
    }
  },
}

export default theme
