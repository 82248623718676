import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Slideshow from '../components/slideshow'
import useSettings from '../hooks/useSettings'

const front = 'https://images.ctfassets.net/1a7h0xku0wdm/Ypnw7oLaEZOtFeEy4AfAg/0b531112494d4582082b6c8b0fbb7fc9/AgeOfUnion_TradeBook_Cover_RGB_150DPI_201903128__1_.jpg?w=1000'

const Book = ({ homeLink }) => (
  <div className="cube-wrapper">
    <div className="cube-position">
      <Link to={homeLink} className="cube">
        <div className="front">
          <img src={front} alt='Igniting the Changemaker' />
        </div>
        <div className="back"></div>
        <div className="left" />
        <div className="right" />
        <div className="top" />
        <div className="bottom" />
      </Link>
    </div>
  </div>
)

const Nav = ({ image, langs, settings, homeLink, ready, style}) => {

  // useEffect(() => {

  // }, [])

  // const handleTransitionEnd = () => {
  //   if (ready) {
  //     this.props.handleTransitionEnd()
  //     this.removeEventListener()
  //   }
  // }

  return (
    <nav className={ready ? 'ready' : ''}  style={style}>
      <div className="fs">
        <Slideshow images={image} />
      </div>
      <div className="nav-links top">
        <Link to={homeLink}>
          <svg className="logo" width="413" height="357" viewBox="0 0 413 357" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M67.4996 316.9C67.4996 329.8 58.4996 338.1 44.4996 338.1C30.5996 338.1 21.6996 329.8 21.6996 316.9V249.4H2.59961V316.9C2.59961 328.8 6.59961 338.8 14.0996 345.8C21.4996 352.7 32.0996 356.4 44.5996 356.4C57.1996 356.4 67.6996 352.7 75.1996 345.8C82.6996 338.8 86.6996 328.8 86.6996 316.9V249.4H67.5996V316.9H67.4996Z" fill="black"/>
          <path d="M160.6 320.5L118.8 249.3H97.6997V355H116.8V284L158.6 355H179.7V249.3H160.6V320.5Z" fill="black"/>
          <path d="M209.9 249.3H190.9V355H209.9V249.3Z" fill="black"/>
          <path d="M270.5 248C256.7 248 244 253.3 234.8 262.9C225.2 273 220.1 286.8 220.1 302.9C220.1 333.9 241.3 356.4 270.5 356.4C299.8 356.4 321 333.9 321 302.9C321 286.6 315.9 272.7 306.3 262.7C297.2 253.2 284.5 248 270.5 248ZM292.5 328.5C287.1 334.8 279.5 338.2 270.6 338.2C261.6 338.2 254 334.9 248.6 328.7C243.1 322.3 240.2 313.1 240.2 302.2C240.2 279.7 251.6 266.3 270.6 266.3C289.6 266.3 301 279.7 301 302.2C301 312.9 298 322 292.5 328.5Z" fill="black"/>
          <path d="M393.4 320.5L351.6 249.3H330.5V355H349.6V284L391.4 355H412.5V249.3H393.4V320.5Z" fill="black"/>
          <path d="M184 143.9V125.5H112.2V158.6H131.2V143.9H184Z" fill="black"/>
          <path d="M87.3997 138.801C78.2997 129.301 65.4997 124.101 51.5997 124.101C37.7997 124.101 25.0997 129.401 15.8997 139.001C6.29971 149.101 1.19971 162.901 1.19971 179.001C1.19971 210.001 22.3997 232.501 51.5997 232.501C78.3997 232.501 98.4997 213.601 101.7 186.501H107.5H126.5H412.5V168.201H101.4C99.5997 156.601 94.8997 146.501 87.3997 138.801ZM73.5997 204.601C68.1997 210.901 60.5997 214.301 51.6997 214.301C42.6997 214.301 35.0997 211.001 29.6997 204.801C24.1997 198.401 21.2997 189.201 21.2997 178.301C21.2997 155.801 32.6997 142.401 51.6997 142.401C70.6997 142.401 82.0997 155.801 82.0997 178.301C82.0997 189.101 79.0997 198.201 73.5997 204.601Z" fill="black"/>
          <path d="M131.2 196.2H112.2V231.2H131.2V196.2Z" fill="black"/>
          <path d="M33.4998 78.3006H68.8998L79.5998 107.201H101.5L60.7998 1.60059H41.3998L0.799805 107.301H22.8998L33.4998 78.3006ZM51.1998 30.1006L62.0998 59.9006H40.1998L51.1998 30.1006Z" fill="black"/>
          <path d="M199.2 44.5002H140.3H139.3V62.7002H182.3C182 69.1002 176.5 77.6002 172.9 81.3002C169 85.5002 162 90.4002 151.4 90.4002C141.9 90.4002 133.4 86.8002 127.2 80.3002C121 73.8002 117.7 64.5002 117.7 54.4002C117.7 32.6002 130.7 18.5002 150.7 18.5002C160 18.5002 167 21.1002 171.8 26.3002C174.3 29.1002 175.8 32.2002 176.7 34.8002H197.5C194 14.3002 175.2 0.200195 150.8 0.200195C137.2 0.200195 124.5 4.5002 115.1 12.3002C103.6 21.9002 97.5 36.4002 97.5 54.4002C97.5 69.8002 102.8 83.6002 112.3 93.4002C121.9 103.2 135.4 108.6 150.5 108.6C162.7 108.6 173 104.4 180.1 99.4002V107.2H199.2V62.6002H412.5V44.5002H199.2V44.5002Z" fill="black"/>
          <path d="M229.8 20.1006H286.7V1.60059H210.7V34.9006H229.8V20.1006Z" fill="black"/>
          <path d="M210.7 72.2998V107.3H286.7V88.7998H229.8V72.2998H210.7Z" fill="black"/>
          </svg>
        </Link>
        {settings.showLanguages ? (
          <div className="align-right">
						<div>
							{langs.map((item, i) => (
								<Link to={item.link} className={item.selected ? 'lang selected' : 'lang'} key={`lang-toggle-${item.langKey}`}>{item.langKey}</Link>
							))}
						</div>
						<Link to={`${homeLink}about`}>{settings.about}</Link>
          </div>
        ) : (
          <Link to={`${homeLink}about`}>{settings.about}</Link>
        )}
      </div>
      <div className="nav-links bottom">
        <Link to={`${homeLink}events`}>{settings.menuEvents}</Link>
        <Link to={`${homeLink}features`}>{settings.menuFeatures}</Link>
        {/* <LinkOut href={featuredBook.buyLink}>{featuredBook.buyLinkText}</LinkOut> */}
      </div>
    </nav>
  )
}

// <img src={logo} alt='Age Of Union' className="logo" />

export default Nav
