/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title, shareImage }) {

  const { allContentfulSettings } = useStaticQuery(
    graphql`
      query {
        allContentfulSettings {
          edges {
            node {
              seoTitle
              seoDescription
              seoImage {
                fluid(maxWidth: 600, quality: 70) {
                  src
                }
              }
            }
          }
        }
      }
    `
  )

	
  const settings = allContentfulSettings.edges[0].node
  const seoTitle = title ? `${title} | ${settings.seoTitle}` : settings.seoTitle
  const seoDescription = description || settings.seoDescription
  const image = shareImage && shareImage.fluid.src || settings.seoImage && settings.seoImage.fluid.src || "";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seoTitle}
      meta={[
        {
          name: `description`,
          content: seoDescription,
        },
        {
          property: `og:title`,
          content: seoTitle,
        },
        {
          property: `og:description`,
          content: seoDescription,
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@ageofunion',
        },
        {
          name: `twitter:title`,
          content: seoTitle,
        },
        {
          name: `twitter:description`,
          content: seoDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
