import React, { useState, useEffect } from 'react'
import IMG from '../components/image'

const Slideshow = ({ images }) => {

  const [active, setActive] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (active !== images.length - 1) {
        setActive(active + 1)
      } else {
        setActive(0)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [active])

  const handleClick = () => {
    if (active !== images.length - 1) {
      setActive(active + 1)
    } else {
      setActive(0)
    }
  }

  return (
    <div className="slideshow fs flex-center" onClick={handleClick}>
      {images.map((image, index) => (
        <IMG fluid={image.fluid} key={index} className={active === index ? "active" : ""} />
      ))}
    </div>
  )
}

export default Slideshow
