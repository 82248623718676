// Dependencies
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { IntlProvider, addLocaleData } from 'react-intl';
// i18n data
import 'intl';
import en from 'react-intl/locale-data/en';
import 'intl/locale-data/jsonp/en';
import fr from 'react-intl/locale-data/fr';
import 'intl/locale-data/jsonp/fr';
// CSS
import '../css/app.scss'
// Components
import theme from '../helpers/theme'
import checkStorage from '../helpers/session'
import Nav from '../components/nav'
import SEO from '../components/seo'
import LinkOut from '../components/link-out'


addLocaleData([...en, ...fr]);

const Layout = ({ children, theme, settings, size, location, i18nMessages, image }) => {

  useEffect(() => {
    document.documentElement.style.setProperty('--background', theme.main.background);
    document.documentElement.style.setProperty('--foreground', theme.main.color);
    document.documentElement.style.setProperty('--highlight', theme.main.highlight);
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
          allContentfulSettings {
            edges {
              node {
                navImageHome {
                  fluid {
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = langKey === 'en' ? '/' : '/fr/';
        const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));

        return (
          <IntlProvider
            locale={langKey}
            messages={i18nMessages}
          >
            <div id="container" className={size}>
							<SEO />
              {/* <Helmet
                link={[
                  {
                    rel: 'stylesheet',
                    href: 'https://webfonts.fontstand.com/WF-040866-b9b44b79fb589dff7af561975fbd4937.css',
                    type: 'text/css'
                  }
                ]}
              /> */}
              
              <Nav
                image={image}
                langs={langsMenu}
                settings={settings}
                homeLink={homeLink}
                ready={true}
                style={{
                // backgroundColor: theme.nav.background,
                // color: theme.nav.color,
                // transform: `translateY(${!this.state.ready ? '100vh' : '0'})`,
                transform: 0,
                transition: 'transform 0.8s'
              }}  />
              <main style={{
                // backgroundColor: theme.main.background,
                // color: theme.main.color,
                // transform: `translateY(${!this.state.ready ? '100vh' : '0'})`,
                transform: 0,
                transition: 'transform 0.8s'
              }}>
                {children}
                <footer className="innerx2 has-links flex-end">
                  <div className="cols--about gutters-weak">
                    {/*<div className="span-6 gutters">
                      <span className="inlb">Follow @ageofunion...</span>
                    </div>8*/}
                    <div className="span-6 gutters flex">
                      <LinkOut href="https://www.instagram.com/ageofunion" className="mr">Instagram</LinkOut>
                      <LinkOut href="https://www.twitter.com/ageofunion" className="mr">Twitter</LinkOut>
                      <LinkOut href="https://www.facebook.com/ageofunion" className="mr">Facebook</LinkOut>
                    </div>
                  </div>
                </footer>
								
              </main>
            </div>
          </IntlProvider>
        )
      }}
    />
  )
}

// Layout.defaultProps = {
//   theme: theme.green,
//   size: ''
// }
//
// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
